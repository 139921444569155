export const SUBSCRIPTION_PLAN_ELITE = 'elite'
export const SUBSCRIPTION_PLAN_ONBOARDING = 'onboarding'
export const SUBSCRIPTION_PLAN_FREE = 'free'
export const SUBSCRIPTION_PLAN_ADVANCED = 'advanced'
export const SUBSCRIPTION_PLAN_PREMIUM = 'premium'
export const SUBSCRIPTION_PLAN_DOMAIN = 'domain'

export const SUBSCRIPTION_ADD_ON_TURBO_SALES_BASIC = 'turbo_sales_basic'
export const SUBSCRIPTION_ADD_ON_TURBO_SALES_HIGH = 'turbo_sales_high'
export const SUBSCRIPTION_ADD_ON_TURBO_SALES_MASSIVE = 'turbo_sales_massive'

export const PLAN_PRIORITIES = {
  [SUBSCRIPTION_PLAN_ELITE]: 5,
  [SUBSCRIPTION_PLAN_PREMIUM]: 4,
  [SUBSCRIPTION_PLAN_ADVANCED]: 3,
  [SUBSCRIPTION_PLAN_ONBOARDING]: 2,
  [SUBSCRIPTION_PLAN_FREE]: 1
}
