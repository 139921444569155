import {
  ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER,
  ROLE_COMPANY_WAITER
} from '@/constants/userRoles.js'
import BlankLayout from '@/layouts/BlankLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

export default [
  {
    path: '/',
    name: 'welcome',
    redirect: 'not-found',
    meta: {
      isPublic: false
    }
  },
  {
    path: '/coupons',
    name: 'coupons',
    component: () => import('@/pages/CouponsPage.vue'),
    meta: {
      layout: DefaultLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
    }
  },
  {
    path: '/business-hours',
    name: 'business-hours',
    component: () => import('@/pages/BusinessHoursPage.vue'),
    meta: {
      layout: DefaultLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER, ROLE_COMPANY_WAITER]
    }
  },
  // {
  //   path: '/statistics',
  //   name: 'statistics',
  //   component: () => import('@/pages/StatisticsPage.vue'),
  //   meta: {
  //     layout: DefaultLayout,
  //     isPublic: false,
  //     availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
  //   }
  // },
  {
    path: '/setting-team',
    name: 'setting-team',
    component: () => import('@/pages/MyTeamPage.vue'),
    meta: {
      layout: DefaultLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN]
    }
  },
  {
    path: '/landing-page-configuration',
    name: 'landing-page-configuration',
    component: () => import('@/pages/LandingPageConfigurationPage.vue'),
    meta: {
      layout: DefaultLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
    }
  },
  {
    path: '/social-networks',
    name: 'social-networks',
    component: () => import('@/pages/SocialNetworksPage.vue'),
    meta: {
      layout: DefaultLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
    }
  },
  {
    path: '/trackings',
    name: 'trackings',
    component: () => import('@/pages/TrackingsPage.vue'),
    meta: {
      layout: DefaultLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/pages/PricingPage.vue'),
    meta: {
      layout: BlankLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER, ROLE_COMPANY_WAITER]
    }
  },
  {
    path: '/benefits',
    name: 'benefits',
    component: () => import('@/pages/PartnersPage.vue'),
    meta: {
      layout: DefaultLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER, ROLE_COMPANY_WAITER]
    }
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import('@/pages/SubscriptionsPage.vue'),
    meta: {
      layout: DefaultLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER, ROLE_COMPANY_WAITER]
    }
  },
  {
    path: '/subscriptions/:id/renew',
    name: 'subscription_renew',
    component: () => import('@/pages/SubscriptionRenewPage.vue'),
    meta: {
      layout: BlankLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
    }
  },
  {
    path: '/payment-callback',
    name: 'paymentCallback',
    component: () => import('@/pages/PaymentCallback.vue'),
    meta: {
      layout: BlankLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
    }
  },
  {
    path: '/search-domain',
    name: 'search-domain',
    component: () => import('@/pages/SearchDomainPage.vue'),
    meta: {
      layout: BlankLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
    }
  },
  {
    path: '/subscribe-fiscal-notes',
    name: 'subscribe-fiscal-notes',
    component: () => import('@/pages/SubscribeFiscaNotesPage.vue'),
    meta: {
      layout: BlankLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
    }
  },
  {
    path: '/subscribe-turbo-sales-ai',
    name: 'subscribe-turbo-sales-ai',
    component: () => import('@/pages/SubscribeTurboSalesAiPage.vue'),
    meta: {
      layout: BlankLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
    }
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import('@/pages/MyReviewsPage.vue'),
    meta: {
      layout: DefaultLayout,
      isPublic: false,
      availableTo: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER]
    }
  },

  /* La ruta not-found debe quedar al final */
  {
    path: '*',
    name: 'not-found',
    component: () => import('@/pages/NotFoundPage.vue'),
    meta: {
      layout: BlankLayout,
      isPublic: true
    }
  }
]
